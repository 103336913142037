import { PanoGL, Box3, Vector3, Scene, ScalablePlugin } from './ImportHelper'
import { PointBox } from './PointBox'

class PointLoader {
    constructor (url, serviceID, rootPano) {
        this._scene = new Scene()
        this._disabled = false
        this._url = url
        this.panogl = rootPano
        this._bounds = []
        this._queue = []
        this._inProgress = []
        this._currentGID = 0
        this._serviceID = serviceID
        rootPano.addEvent(PanoGL.DATA_COMPLETE, this, this.onDataComplate)
    }

    setDisable (b) {
        // let rootPano = this.panogl
        if (this._disabled !== b) {
            if (!b) {
                this.refresh()
            } else {
                this.clear()
            }

            const panogl = this.panogl
            panogl.setDirty()
        }

        this._disabled = b

        // if (!b) {
        //     rootPano.addEvent(PanoGL.DATA_COMPLETE, this, this.onDataComplate)
        // }
    }

    onDataComplate (e) {
        let standingPt = e.data.current
        this._standingPoint = standingPt
        this._currentGID = standingPt.gid
        clearTimeout(this._timeout)
        this.clear()

        if (this._disabled) return

        this._timeout = setTimeout(() => this.refresh(), 200)
    }

    clear () {
        // No need to destroy bounds inside q since they all are in bounds
        this._inProgress.length = 0
        this._queue.length = 0

        let bounds = this._bounds
        bounds.forEach(b => b.destroy())
        bounds.length = 0
    }

    refresh () {
        let standingPoint = this._standingPoint
        if (standingPoint) {
            let { lat, lon } = standingPoint
            this.loadData(lon, lat)
        }
    }

    loadData (lon, lat) {
        let url = this._url + `pc/around/${this._serviceID}/${lon}/${lat}`
        // const { stamp } = this._standingPoint
        // let url = this._url + `pc/aroundByTime/${this._serviceID}/${lon}/${lat}/${stamp}`
        let currentGID = this._currentGID
        AnkaPanAPI.AnkaFetch(url)
            .then(e => e.json())
            .then((json) => {
                if (json.success) {
                    if (currentGID === this._currentGID) {
                        this._setPointParams(json.points)
                    }
                } else {
                    console.log('No data found')
                }

                const panogl = this.panogl
                panogl.setDirty()
            }).catch(e => {
                console.log(e)
            })
    }

    _setPointParams (points) {
        points.forEach((b, index) => {
            let maxx = parseFloat(b.maxx)
            let maxy = parseFloat(b.maxy)
            let maxz = parseFloat(b.maxz)

            let minx = parseFloat(b.minx)
            let miny = parseFloat(b.miny)
            let minz = parseFloat(b.minz)

            const panogl = this.panogl

            let inPixel = panogl.getGroundMaster().cameraHeightInPixel
            let inMeter = panogl.getGroundMaster().cameraHeight
            let standingPoint = this._standingPoint
            let minPos = ScalablePlugin.loc2Pos(standingPoint, minx, miny, minz, inPixel, inMeter)
            let maxPos = ScalablePlugin.loc2Pos(standingPoint, maxx, maxy, maxz, inPixel, inMeter)

            let min = new Vector3(minPos.x, minPos.y, minPos.z)
            let max = new Vector3(maxPos.x, maxPos.y, maxPos.z)

            let bound = new Box3(min, max)

            let pointBox = new PointBox(panogl, this._scene, bound)
            pointBox.gid = this._currentGID
            pointBox.bid = this._currentGID + '_' + index
            pointBox.setData(b, standingPoint)

            pointBox.addEvent(PointBox.SEEN, this, this._onBoundSeen)
            pointBox.addEvent(PointBox.COMPLETE, this, this._onBoxAddedToScene)
            this._bounds.push(pointBox)
        })
    }

    _onBoundSeen (e) {
        let bound = e.target
        this._queue.push(bound)
        this._loadNext()
    }

    _onBoxAddedToScene (e) {
        let bound = e.target
        let inProgress = this._inProgress
        let index = inProgress.indexOf(bound)
        inProgress.splice(index, 1)

        const panogl = this.panogl
        panogl.setDirty()
        this._loadNext()
    }

    _loadNext () {
        if (this._inProgress.length < 10) {
            if (this._queue.length > 0) {
                let bound = this._queue.shift()
                this._inProgress.push(bound)
                let data = bound.getData()
                let url = this._url + `pc/fetch/${this._serviceID}/${data.id}`
                bound.load(url)
            }
        }
    }

    update () {
        if (this._disabled) return

        const panogl = this.panogl
        const scene = this._scene
        const renderer = panogl.getRenderer()
        const camera = panogl.getMainCamera()
        renderer.clearDepth()
        renderer.render(scene, camera)
    }
}

export { PointLoader }
